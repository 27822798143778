import * as React from "react"
import * as TitleStyles from "./title.module.scss";
import classNames from "classnames";

const Title = ({ children, inverted, className = '' }) => {
    const BuittonClasses = classNames(
        TitleStyles.wrapper,
        {
            [TitleStyles.inverted]: inverted
        },
        `${className}`
    );

    return (
        <span className={BuittonClasses} >
            {children}
        </span>
    )
}

export default Title
