import * as React from "react"
import * as TwoContainersLayoutStyles from "./twoContainersLayout.module.scss";
import classNames from "classnames";

const TwoContainersLayout = ({ className, styles, children }) => {
  const wrapperClassnames = classNames(
    TwoContainersLayoutStyles.wrapper,
    className,
  );

  return (
    <div className={wrapperClassnames} style={styles}>
      {children}
    </div>
  );
}

export default TwoContainersLayout
