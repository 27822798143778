import * as React from "react";
import { graphql } from "gatsby";

import Collapsible from "react-collapsible";

import Layout from "../components/layout";
import Button from "../components/ui/button";
import Title from "../components/ui/title";
import Section from "../components/layout/section";
import TwoContainersLayout from "../components/layout/twoContainersLayout";
import * as styles from "./faq.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import Arrow from "../assets/svg/arrow.svg";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import classNames from "classnames";
import { SEOv2 } from "../components/seov2";

const FAQPage = ({ data }) => (
  <>
    <Layout>
      <Section type="lightest-green" className={styles.firstSectionContainer}>
        <TwoContainersLayout>
          <div className={styles.left}>
            <span className={styles.heading}>Често задавани въпроси</span>
          </div>
          <div className={styles.right}>
            <StaticImage
              className={styles.heroImage}
              objectFit="contain"
              alt="faq hero image"
              src="../assets/images/faq.png"
              placeholder="blurred"
            />
          </div>
        </TwoContainersLayout>
      </Section>
      <Section type="white" className={styles.sectionContainer}>
        {data.allStrapiFaq.edges.map((faq) => {
          return (
            <Collapsible
              key={faq.node.id}
              trigger={
                <div className={styles.trigger}>
                  <div>{faq.node.Question}</div>
                  <div className={styles.arrow}>
                    <Arrow />
                  </div>
                </div>
              }
              className={styles.collapsable}
              openedClassName={styles.collapsable}
              triggerOpenedClassName={styles.triggerOpened}
              contentInnerClassName={styles.content}
            >
              <ReactMarkdown className={classNames(styles.markdown)}>
                {faq.node.childStrapiFaqAnswerTextnode?.Answer}
              </ReactMarkdown>
            </Collapsible>
          );
        })}
      </Section>
      <Section type="nearly-black" className={styles.sectionContainer}>
        <Title inverted={true} className={styles.mobileTitle}>
          Свържете се с нас
        </Title>
        <Button
          className={styles.button}
          inverted
          title={"Свържете се с нас"}
          to="/contacts"
        />
      </Section>
    </Layout>
  </>
);

export const query = graphql`
  query {
    allStrapiFaq {
      edges {
        node {
          id
          Question
          childStrapiFaqAnswerTextnode {
            Answer
          }
        }
      }
    }
  }
`;

export default FAQPage;

export const Head = () => <SEOv2 title="FAQ" previewImg="faq" />;
