// extracted by mini-css-extract-plugin
export var arrow = "faq-module--arrow--23a65";
export var button = "faq-module--button--ea848";
export var collapsable = "faq-module--collapsable--acbf7";
export var content = "faq-module--content--08aa0";
export var heading = "faq-module--heading--7acf9";
export var heroImage = "faq-module--heroImage--b526e";
export var left = "faq-module--left--0359d";
export var markdown = "faq-module--markdown--e4543";
export var mobileTitle = "faq-module--mobileTitle--5db85";
export var right = "faq-module--right--0ade5";
export var sectionContainer = "faq-module--sectionContainer--9b75c";
export var trigger = "faq-module--trigger--1f0a7";
export var triggerOpened = "faq-module--triggerOpened--b0d9b";